import { createApp } from 'vue';
import CalculatorWrap from './ui/components/3-organisms/Calculator/CalculatorWrap.vue';
import initCleaves from '@/ui/directives/cleave';
import { createPinia } from 'pinia';

const pinia = createPinia();
const apps = [];
window.addEventListener('pageshow', (e) => {
  const allCalculators = document.getElementsByClassName('m-calculator');

  for (let i = 0; i < allCalculators.length; i++) {
    let mountElementDataset = allCalculators[i].dataset;
    const app = createApp(CalculatorWrap, {
      config: mountElementDataset,
      componentId: i,
    });

    initCleaves(app);
    app.use(pinia);
    app.mount(allCalculators[i]);
    apps.push(app);
  }
});
